// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./../../caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-en-404-js": () => import("./../../../src/pages/en/404.js" /* webpackChunkName: "component---src-pages-en-404-js" */),
  "component---src-pages-help-en-js": () => import("./../../../src/pages/help.en.js" /* webpackChunkName: "component---src-pages-help-en-js" */),
  "component---src-pages-help-pt-js": () => import("./../../../src/pages/help.pt.js" /* webpackChunkName: "component---src-pages-help-pt-js" */),
  "component---src-pages-index-en-js": () => import("./../../../src/pages/index.en.js" /* webpackChunkName: "component---src-pages-index-en-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-index-pt-js": () => import("./../../../src/pages/index.pt.js" /* webpackChunkName: "component---src-pages-index-pt-js" */),
  "component---src-pages-pricing-en-js": () => import("./../../../src/pages/pricing.en.js" /* webpackChunkName: "component---src-pages-pricing-en-js" */),
  "component---src-pages-pricing-pt-js": () => import("./../../../src/pages/pricing.pt.js" /* webpackChunkName: "component---src-pages-pricing-pt-js" */),
  "component---src-pages-privacy-policy-en-js": () => import("./../../../src/pages/privacy-policy.en.js" /* webpackChunkName: "component---src-pages-privacy-policy-en-js" */),
  "component---src-pages-privacy-policy-pt-js": () => import("./../../../src/pages/privacy-policy.pt.js" /* webpackChunkName: "component---src-pages-privacy-policy-pt-js" */),
  "component---src-pages-pt-404-js": () => import("./../../../src/pages/pt/404.js" /* webpackChunkName: "component---src-pages-pt-404-js" */),
  "component---src-pages-terms-of-use-en-js": () => import("./../../../src/pages/terms-of-use.en.js" /* webpackChunkName: "component---src-pages-terms-of-use-en-js" */),
  "component---src-pages-terms-of-use-pt-js": () => import("./../../../src/pages/terms-of-use.pt.js" /* webpackChunkName: "component---src-pages-terms-of-use-pt-js" */)
}

